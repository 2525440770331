import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton } from "@mui/material";
import Colors from "assets/style/Colors";
import { useTranslation } from "react-i18next";

export const PrimaryButton = (props) => {

  const { t } = useTranslation();

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: props.disabled ? Colors.flashWhite : props.btnColor,
        color: props.disabled ? Colors.smokyBlack : Colors.white,
        textTransform: 'capitalize',
        boxShadow: 'none',
        minWidth: '120px',
        '&:hover': {
          background: props.disabled ? Colors.flashWhite : props.btnColor,
          color: props.disabled ? Colors.smokyBlack : Colors.white,
        }
      }}
    >
      {t(props.title)}
    </LoadingButton>
  );
}

export const SecondaryButton = (props) => {

  const { t } = useTranslation();

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: Colors.secondaryGradient,
        color: Colors.white,
        textTransform: 'capitalize',
        minWidth: '120px',
        '&:hover': {
          background: Colors.secondaryGradient,
          color: Colors.white,
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        }
      }}
    >
      {t(props.title)}
    </LoadingButton>
  );
}

export const TertiaryButton = (props) => {

  const { t } = useTranslation();

  return (
    <LoadingButton
      variant="outlined"
      {...props}
      sx={{
        borderColor: props.disabled ? Colors.flashWhite : props.btnColor,
        color: props.disabled ? Colors.smokyBlack : props.btnColor,
        textTransform: 'capitalize',
        borderRadius: '8px',
        boxShadow: 'none',
        Width: '40px',
        '&:hover': {
          borderColor: props.disabled ? Colors.flashWhite : props.btnColor,
          color: props.disabled ? Colors.smokyBlack : props.btnColor,

        }
      }}
    >
      {t(props.title)}
    </LoadingButton>
  );
}

export const ActionButton = (props) => {
  return (
    <IconButton {...props} sx={{ mx: 0.5, p: 0 }}>
      {props.children}
    </IconButton>
  );
}

export const PaginationButton = (props) => {
  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        py: 0.5,
        mx: 0.5,
        textTransform: 'capitalize',
        bgcolor: Colors.white,
        color: Colors.black,
        '&:hover': { bgcolor: Colors.white, color: Colors.black }
      }}
    >
      {props.children}
    </Button>
  );
}

export const BackButton = ({ onClick }) => {
  return (
    <IconButton
      aria-label="back"
      onClick={onClick}
      size="small"
      sx={{
        bgcolor: Colors.tertiary,
        color: Colors.white,
        '&:hover': {
          bgcolor: Colors.tertiary,
          color: Colors.white
        }
      }}
    >
      <ArrowBack />
    </IconButton>
  );
}