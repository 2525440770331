import { useState, useEffect } from "react";
import {
  Close,
  CreditCard,
  Restore,
  TextSnippet,
  LocationOn,
  ChevronRight,
  DeliveryDining,
  PhotoAlbum,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardMedia,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import Colors from "assets/style/Colors";
import { BaseUrl } from "../../axios";
import { PrimaryButton, SecondaryButton } from "components/buttons/Buttons";
import { CircleLoading } from "components/loaders/Loaders";
import { useLanguage } from "context/UseContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Images, {
  AdditionalDocsIcon,
  PrescriptionIcon,
} from "assets/images/Images";
import { useNavigate } from "react-router-dom";

const googleMapKey = process.env.REACT_APP_MAP_API_KEY;

function Map({ lat, lng }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapKey,
    libraries: ["places"],
  });

  const containerStyle = {
    width: "100%",
    height: "150px",
    borderRadius: "6px",
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
  };

  if (!isLoaded) return <CircleLoading />;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: lat, lng: lng }}
      zoom={14}
      options={options}
    >
      <MarkerF position={{ lat: lat, lng: lng }} />
    </GoogleMap>
  );
}

function OrderDetailDialog({
  open,
  onClose,
  orderDetail,
  accept,
  reject,
  payment,
}) {
  console.log("🚀 orderDetail", orderDetail);
  const tableHead = ["Image", "Name", "Qty", "Price"];
  const [deliveryCharges, setDeliveryCharges] = useState(5);
  const { lang } = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const joinImagePath = orderDetail?.result?.prescription?.split(",");

  // const totalDiscountAmount = orderDetail?.result?.productDetails?.reduce((total, amount));

  // const totalDiscountAmount = orderDetail?.result?.productDetails?.reduce(
  //   (total, product) => {
  //     const priceDifference = product.price - product.discounted_price;
  //     return total + priceDifference;
  //   },
  //   0
  // );

  // const totalProductAmount = orderDetail?.result?.productDetails?.reduce(
  //   (total, product) => {
  //     return total + product.price;
  //   },
  //   0
  // );

  const handleOpenInNewtab = (path) => {
    if (!path) {
      throw new Error("Resource URL not provided! You need to provide one");
    }

    const openURL = path.startsWith("blob:") ? path : `${BaseUrl}${path}`;

    window.open(openURL, "_blank");
  };

  function capitalizeFirstLetter(inputString) {
    return `${inputString.charAt(0).toUpperCase()}${inputString.slice(1)}`;
  }

  let totalDiscountedAmount = 0;
  let totalBillAmount;

  const totalActualAmount = orderDetail?.result?.productDetails?.reduce(
    (total, product) => {
      return total + product.price * product.qty;
    },
    0
  );

  const productsWithDiscount = orderDetail?.result?.productDetails?.filter(
    (item) => item.discounted_price
  );

  const productDiscountedAmount = productsWithDiscount?.reduce(
    (total, product) => {
      return (
        total +
        (product.price * product.qty - product.discounted_price * product.qty)
      );
    },
    0
  );

  const voucherDiscountedAmount =
    orderDetail?.billing?.voucher_details &&
    Object.values(orderDetail?.billing?.voucher_details)?.reduce((total) => {
      return (total =
        orderDetail?.billing?.voucher_details.total_amount -
        orderDetail?.billing?.voucher_details.discounted_amount);
    }, 0);

  if (orderDetail?.billing?.voucher_details) {
    totalDiscountedAmount = voucherDiscountedAmount;
    totalBillAmount =
      totalActualAmount - totalDiscountedAmount + deliveryCharges;
  }

  if (
    orderDetail?.billing?.voucher_details ||
    orderDetail?.result?.productDetails.includes("discounted_price") > 0
  ) {
    totalDiscountedAmount = productDiscountedAmount + voucherDiscountedAmount;
    totalBillAmount =
      totalActualAmount - totalDiscountedAmount + deliveryCharges;
  }

  if (
    !orderDetail?.billing?.voucher_details ||
    orderDetail?.result?.productDetails.includes("discounted_price") > 0
  ) {
    totalDiscountedAmount = productDiscountedAmount;
    totalBillAmount =
      totalActualAmount - totalDiscountedAmount + deliveryCharges;
  }

  return (
    <Dialog
      dir={lang === "ar" ? "rtl" : "ltr"}
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          my: { xs: 2, md: 4 },
          mx: { xs: 1, md: 4 },
          width: { xs: "100%", md: "60%" },
          height: "auto",
          borderRadius: 2,
          py: { xs: 1, md: 1.5 },
          px: { xs: 0.8, md: 1.5 },
        },
      }}
    >
      <IconButton
        color="primary"
        onClick={() => onClose()}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close />
      </IconButton>
      <DialogTitle
        sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
      >
        {t("orderDetail")}
      </DialogTitle>
      <DialogContent sx={{ px: { xs: 2, md: 3 } }}>
        <Box
          sx={{
            p: 1.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            // borderRadius: "12px",
            // boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
            mb: 1,
            // border: `1px solid ${Colors.iron}`,
            gap: "5px",
            backgroundImage:
              orderDetail?.result?.status?.name === "Completed"
                ? Colors.completeTopDown
                : orderDetail?.result?.status?.name === "Confirmed"
                ? Colors.confirmTopDown
                : orderDetail?.result?.status?.name === "Accepted"
                ? Colors.acceptedTopDown
                : orderDetail?.result?.status?.name === "Quoted"
                ? Colors.quotedTopDown
                : Colors.requestedTopDown,
            position: "relative",
            height: "100px",
            mb: "40px",
          }}
        >
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, color: Colors.white }}
            >
              {t("status")} :
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: Colors.white,
              }}
            >
              {lang === "ar"
                ? orderDetail?.result?.status?.ar
                : orderDetail?.result?.status?.name}
            </Typography>
          </Box>
          <Box
            sx={{ width: "100%", height: "100%", position: "relative" }}
          ></Box>
          <Card
            sx={{
              // p: 1.5,
              display: "flex",
              justifyContent: "space-evenly",
              border: `1px solid ${Colors.iron}`,
              borderRadius: "12px",
              boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
              mb: 1,
              position: "absolute",
              top: "70%",
              left: "4.5%",
              width: "90%",
            }}
          >
            <Box
              flex="50%"
              sx={{
                borderRight: `1px solid ${Colors.iron}`,
                textAlign: "center",
                p: 1,
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {t("orderId")}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: Colors.primary }}
              >
                {orderDetail?.result?.order_id}
              </Typography>
            </Box>
            <Box flex="50%" sx={{ textAlign: "center", p: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {t("dateTime")}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", color: Colors.primary }}
              >
                {moment(orderDetail?.result?.createdAt)
                  .locale(lang === "ar" ? "ar" : "en")
                  .format("DD-MM-YYYY hh:mm A")}{" "}
              </Typography>
            </Box>
          </Card>
        </Box>
        {orderDetail?.result?.prescription && (
          <Card
            sx={{
              p: 1.5,
              borderRadius: "12px",
              boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
              mb: 1,
              border: `1px solid ${Colors.iron}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                mb: "10px",
              }}
            >
              <PrescriptionIcon width={"20px"} height={"20px"} />
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {t("prescription")}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={5}
                slidesPerView={3}
                navigation
                pagination={{ clickable: true }}
                style={{ height: "100px" }}
              >
                {joinImagePath?.map((imagePath, i) => (
                  <SwiperSlide key={i}>
                    <Box
                      component={"img"}
                      src={BaseUrl + imagePath}
                      alt={"prescription"}
                      sx={{
                        height: "80px",
                        width: "100px",
                        objectFit: "cover",
                        margin: "auto",
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Card>
        )}
        {orderDetail?.result?.attachments &&
          orderDetail?.result?.attachments?.length !== 0 && (
            <Card
              sx={{
                p: 1.5,
                borderRadius: "12px",
                boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
                mb: 1,
                border: `1px solid ${Colors.iron}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  mb: "10px",
                }}
              >
                <AdditionalDocsIcon width={"20px"} height={"20px"} />
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t("additionalDocs")}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Swiper
                  modules={[Navigation, Pagination]}
                  spaceBetween={5}
                  slidesPerView={3}
                  navigation
                  pagination={{ clickable: true }}
                  style={{ height: "100px" }}
                >
                  {orderDetail?.result?.attachments?.map((imagePath, i) => {
                    if (imagePath.toLowerCase().includes(".pdf")) {
                      return (
                        <SwiperSlide key={i}>
                          <Box
                            component={"img"}
                            src={Images.pdfIcon}
                            alt={"prescription"}
                            sx={{
                              height: "80px",
                              width: "100px",
                              objectFit: "cover",
                              margin: "auto",
                            }}
                            onClick={() => handleOpenInNewtab(imagePath)}
                          />
                        </SwiperSlide>
                      );
                    } else if (imagePath.toLowerCase().includes(".docx")) {
                      return (
                        <SwiperSlide key={i}>
                          <Box
                            component={"img"}
                            src={Images.wordIcon}
                            alt={"prescription"}
                            sx={{
                              height: "80px",
                              width: "100px",
                              objectFit: "cover",
                              margin: "auto",
                            }}
                            onClick={() => handleOpenInNewtab(imagePath)}
                          />
                        </SwiperSlide>
                      );
                    } else {
                      return (
                        <SwiperSlide key={i}>
                          <Box
                            component={"img"}
                            src={BaseUrl + imagePath}
                            alt={"prescription"}
                            sx={{
                              height: "80px",
                              width: "100px",
                              objectFit: "cover",
                              margin: "auto",
                            }}
                          />
                        </SwiperSlide>
                      );
                    }
                  })}
                </Swiper>
              </Box>
            </Card>
          )}
        {orderDetail?.result?.productDetails?.length !== 0 && (
          <Card
            sx={{
              p: 1.5,
              borderRadius: "12px",
              boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
              mb: 1,
              border: `1px solid ${Colors.iron}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                mb: "10px",
              }}
            >
              <TextSnippet sx={{ color: Colors.primary }} />
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                {t("products")}
              </Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHead.map((cell, i) => (
                      <TableCell key={i} sx={{ fontSize: "12px", p: "10px" }}>
                        <b>{cell}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDetail?.result?.productDetails?.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell
                        sx={{ p: "10px", cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/marketplace/product/${item._id}`)
                        }
                      >
                        <CardMedia
                          component={"img"}
                          src={BaseUrl + item.picture[0]}
                          sx={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ fontSize: "12px", p: "10px" }}>
                        {item.name}
                      </TableCell>
                      <TableCell sx={{ fontSize: "12px", p: "10px" }}>
                        {item.qty}
                      </TableCell>
                      <TableCell sx={{ fontSize: "12px", p: "10px" }}>
                        {item.price}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        )}
        <Card
          sx={{
            p: 1.5,
            borderRadius: "12px",
            boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
            mb: 1,
            border: `1px solid ${Colors.iron}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              mb: "10px",
            }}
          >
            <LocationOn sx={{ color: Colors.primary }} />
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {t("deliveryAddress")}
            </Typography>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Map
              lat={orderDetail?.result?.address?.latitude}
              lng={orderDetail?.result?.address?.longitude}
            />
          </Box>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {orderDetail?.result?.address?.address}
          </Typography>
        </Card>
        <Card
          sx={{
            p: 1.5,
            borderRadius: "12px",
            boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
            mb: 1,
            border: `1px solid ${Colors.iron}`,
          }}
        >
          <Box display="flex" alignItems="center">
            <CreditCard sx={{ m: 1, color: Colors.primary }} />
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {t("totalBill")}
              </Typography>
              <Chip
                label={
                  orderDetail?.billing?.payment_method === "card"
                    ? "Paid Online"
                    : "Cash on delivery"
                }
                sx={{
                  backgroundImage: Colors.quoted,
                  color: Colors.white,
                }}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ p: 1 }}>{t("amount")}</Typography>
            <Typography sx={{ fontWeight: "bold", p: 1 }}>
              {t("aed")}{" "}
              {orderDetail?.result?.productDetails?.length === 0
                ? orderDetail?.billing?.amount.toFixed(2)
                : totalActualAmount?.toFixed(2)}
            </Typography>
          </Box>
          {orderDetail?.billing?.voucher_details?.code && (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ p: 1 }}>{t("voucherCode")}</Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {orderDetail?.billing?.voucher_details?.code}
              </Typography>
            </Box>
          )}
          {orderDetail?.result?.productDetails?.length !== 0 &&
          orderDetail?.billing?.voucher_details &&
          productDiscountedAmount ? (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ p: 1 }}>{t("discountAmount")}</Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {t("aed")}{" "}
                {(
                  orderDetail?.billing?.voucher_details?.total_amount -
                  orderDetail?.billing?.voucher_details?.discounted_amount +
                  productDiscountedAmount
                ).toFixed(2)}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          {orderDetail?.result?.productDetails?.length !== 0 &&
            orderDetail?.billing?.voucher_details &&
            !productDiscountedAmount && (
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ p: 1 }}>{t("discountAmount")}</Typography>
                <Typography sx={{ fontWeight: "bold", p: 1 }}>
                  {t("aed")}{" "}
                  {(
                    orderDetail?.billing?.voucher_details?.total_amount -
                    orderDetail?.billing?.voucher_details?.discounted_amount
                  ).toFixed(2)}
                </Typography>
              </Box>
            )}
          {orderDetail?.result?.productDetails?.length !== 0 &&
            productsWithDiscount &&
            !orderDetail?.billing?.voucher_details && (
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ p: 1 }}>{t("discountAmount")}</Typography>
                <Typography sx={{ fontWeight: "bold", p: 1 }}>
                  {t("aed")} {productDiscountedAmount.toFixed(2)}
                </Typography>
              </Box>
            )}
          {orderDetail?.result?.productDetails?.length === 0 &&
          orderDetail?.logs?.some(
            (item) => item.status["name"] === "Confirmed"
          ) ? (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ p: 1 }}>{t("voucherCode")}</Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {orderDetail?.billing?.voucher_code}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          {orderDetail?.result?.productDetails?.length === 0 &&
          orderDetail?.logs?.some(
            (item) => item.status["name"] === "Confirmed"
          ) ? (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ p: 1 }}>{t("discountAmount")}</Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {t("aed")} {orderDetail?.billing?.discount?.toFixed(2)}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ p: 1 }}>{t("deliveryCharges")}</Typography>
            <Typography sx={{ fontWeight: "bold", p: 1 }}>
              {t("aed")}{" "}
              {orderDetail?.logs?.some(
                (item) => item.status["name"] === "Confirmed"
              )
                ? deliveryCharges.toFixed(2)
                : Number(0).toFixed(2)}
            </Typography>
          </Box>
          {orderDetail?.billing?.tip > 0 && orderDetail?.billing?.tip && (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ p: 1 }}>{t("tip")}</Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {t("aed")} {orderDetail?.billing?.tip.toFixed(2)}
              </Typography>
            </Box>
          )}
          {orderDetail?.result?.productDetails?.length !== 0 && (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {t("totalAmount")}
              </Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {" "}
                {t("aed")}{" "}
                {orderDetail?.logs?.some(
                  (item) => item.status["name"] === "Confirmed"
                )
                  ? totalBillAmount.toFixed(2)
                  : Number(0).toFixed(2)}
              </Typography>
            </Box>
          )}
          {orderDetail?.result?.productDetails?.length === 0 && (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {t("totalAmount")}
              </Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {" "}
                {t("aed")}{" "}
                {orderDetail?.logs?.some(
                  (item) => item.status["name"] === "Confirmed"
                )
                  ? orderDetail?.billing?.total_amount?.toFixed(2)
                  : Number(0).toFixed(2)}
              </Typography>
            </Box>
          )}
        </Card>
        {orderDetail?.result?.delivery &&
          Object.keys(orderDetail?.result?.delivery)?.length !== 0 && (
            <Card
              sx={{
                p: 1.5,
                borderRadius: "12px",
                boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
                mb: 1,
                border: `1px solid ${Colors.iron}`,
              }}
            >
              <Box display="flex" alignItems="center">
                <DeliveryDining sx={{ m: 1, color: Colors.primary }} />
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("deliverySlot")}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ p: 1 }}>{t("dateTime")}</Typography>
                {orderDetail?.result?.delivery?.nature === "instant" ? (
                  <Typography sx={{ fontWeight: "bold", p: 1 }}>
                    {moment(orderDetail?.result?.delivery?.date_time)
                      .locale(lang === "ar" ? "ar" : "en")
                      .utc()
                      .format("DD MMM YYYY, hh:mm A")}
                  </Typography>
                ) : (
                  <Typography sx={{ fontWeight: "bold", p: 1 }}>
                    {moment(orderDetail?.result?.delivery?.date_time)
                      .locale(lang === "ar" ? "ar" : "en")
                      .utc()
                      .format("DD MMM YYYY")}
                  </Typography>
                )}
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ p: 1 }}>{t("slot")} :</Typography>
                {orderDetail?.result?.delivery?.time_range === "" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      p: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {t(
                        capitalizeFirstLetter(
                          orderDetail?.result?.delivery?.nature
                        )
                      )}
                    </Typography>
                    <Typography variant="body5" sx={{ color: "#C4C4C4" }}>
                      {orderDetail?.result?.delivery?.slot === ""
                        ? t("WithIn4Hours")
                        : capitalizeFirstLetter(
                            orderDetail?.result?.delivery?.slot
                          )}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      p: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {orderDetail?.result?.delivery?.slot === ""
                        ? "With in 4 hours"
                        : capitalizeFirstLetter(
                            orderDetail?.result?.delivery?.slot
                          )}
                    </Typography>
                    <Typography variant="body5" sx={{ color: "#C4C4C4" }}>
                      {orderDetail?.result?.delivery?.time_range}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Card>
          )}
        <Card
          sx={{
            p: 1.5,
            borderRadius: "12px",
            boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
            mb: 1,
            border: `1px solid ${Colors.iron}`,
          }}
        >
          <Box display="flex" alignItems="center">
            <Restore sx={{ m: 1, color: Colors.primary }} />
            <Typography sx={{ fontWeight: "bold" }}>{t("orderLog")}</Typography>
          </Box>
          {orderDetail?.logs.map((item, index) => (
            <Box
              key={index}
              sx={{
                py: 1,
                borderBottom: `1px dashed rgba(145, 158, 171, 0.8)`,
                "&:nth-last-child(1)": { border: "none" },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Box
                  sx={{
                    width: "35px",
                    height: "32px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundImage: Colors.primaryGradient,
                  }}
                >
                  <ChevronRight sx={{ color: Colors.white }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{ color: Colors.primary, fontWeight: "bold" }}
                    >
                      {lang === "ar" ? item?.status?.ar : item?.status?.name}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", fontSize: "11px" }}>
                      {lang === "ar" ? item?.arLog : item?.log}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ textAlign: "right" }}>
                      {moment(item?.createdAt)
                        .locale(lang === "ar" ? "ar" : "en")
                        .format("DD MMM, hh:mm A")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {item?.message && (
                <Box display="flex" alignItems="start">
                  <Typography variant="body2" sx={{ fontWeight: "bold", p: 1 }}>
                    {t("message")}:
                  </Typography>
                  <Typography variant="body2" sx={{ py: 1 }}>
                    {item?.message}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Card>
        {/* {orderDetail?.result?.status?.name === "Quoted" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              textAlign: "center",
            }}
          >
            <PrimaryButton
              title="accept"
              onClick={() => {
                onClose();
                accept();
              }}
              btnColor={Colors.primaryGradient}
            />
            <Box sx={{ mx: 0.5 }} />
            <SecondaryButton
              title="reject"
              onClick={() => {
                onClose();
                reject();
              }}
            />
          </Box>
        )} */}
        {orderDetail?.result?.status?.name === "Quoted" && (
          <PrimaryButton
            fullWidth
            title="payNow"
            onClick={() => {
              onClose();
              payment();
            }}
            btnColor={Colors.primaryGradient}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default OrderDetailDialog;
