import React, { Fragment, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  Typography,
  Grid,
  MenuItem,
  ListItemIcon,
  Drawer,
  IconButton,
  Divider,
  CardMedia,
  Menu,
  Badge,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import Images from "assets/images/Images";
import Colors from "assets/style/Colors";
import { useAuth, useLanguage } from "context/UseContext";
import {
  Dashboard,
  Language,
  Logout,
  Person,
  ShoppingBag,
  Close,
  MenuOutlined,
  SendToMobileOutlined,
  ShoppingCart,
  Search,
} from "@mui/icons-material";
import { ErrorToaster } from "components/toaster/Toaster";
import { useTranslation } from "react-i18next";
import { PrimaryButton, TertiaryButton } from "components/buttons/Buttons";
import { t } from "i18next";
import { ImageAvatar } from "components/avatar/ImageAvatar";
import { BaseUrl } from "../../axios";
import Counter from "components/counter";
import SearchField from "components/fields/SearchField";
import ProductServices from "apis/product/ProductServices";

function DropDown({ anchorEl, openDropdown, handleClose }) {
  const { userLogout } = useAuth();
  const { lang } = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // *For Logout
  const logout = () => {
    try {
      userLogout();
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          ".MuiSvgIcon-root": {
            width: 20,
            height: 20,
            ml: 0.5,
            mr: 0.5,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem onClick={() => navigate("/dashboard")}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        {t("dashboard")}
      </MenuItem>
      <MenuItem onClick={() => navigate("/orders")}>
        <ListItemIcon>
          <ShoppingBag />
        </ListItemIcon>
        {t("myOrders")}
      </MenuItem>
      <MenuItem onClick={() => navigate("/my-profile")}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        {t("myProfile")}
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={() => logout()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        {t("logout")}
      </MenuItem>
    </Menu>
  );
}

function CartDropDown({ cartAnchorEl, openCartDropDown, handleCartClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();

  const { cart, totalAmount } = useSelector((state) => state.cartDataReducer);

  const tableHead = ["products", "quantity", "Total"];

  const handleCheckOut = () => {
    if (user) {
      navigate("/web/marketplace/checkout");
    } else if (!user) {
      navigate("/web/login");
    }
  };

  return (
    <Menu
      anchorEl={cartAnchorEl}
      open={openCartDropDown}
      onClose={handleCartClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          ".MuiSvgIcon-root": {
            width: 20,
            height: 20,
            ml: 0.5,
            mr: 0.5,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      {cart.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography>No products in your cart</Typography>
        </Box>
      ) : (
        <Fragment>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHead.map((cell, i) => (
                    <TableCell key={i}>{t(cell)}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box sx={{ width: "60px", height: "60px" }}>
                          <CardMedia
                            component={"img"}
                            src={BaseUrl + item.picture[0]}
                            sx={{
                              width: "60px",
                              height: "60px",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                        <Typography>{item.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "150px" }}>
                        <Counter data={item} />
                      </Box>
                    </TableCell>
                    <TableCell>{item.totalPrice}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <MenuItem>
            <Button fullWidth onClick={handleCheckOut}>
              {t("checkOut")}
            </Button>
          </MenuItem>
        </Fragment>
      )}
    </Menu>
  );
}

function LangDropDown({
  langAnchorEl,
  openLangDropdown,
  handleClose,
  changeLanguage,
}) {
  return (
    <Menu
      anchorEl={langAnchorEl}
      open={openLangDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          p: 1,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          ".MuiSvgIcon-root": {
            width: 20,
            height: 20,
            ml: 0.5,
            mr: 0.5,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem
        variant="subtitle2"
        onClick={() => changeLanguage("en")}
        sx={{
          cursor: "pointer",
          color: Colors.black,
        }}
      >
        English
      </MenuItem>
      <MenuItem
        variant="subtitle2"
        onClick={() => changeLanguage("ar")}
        sx={{
          cursor: "pointer",
          color: Colors.black,
        }}
      >
        Arabic
      </MenuItem>
    </Menu>
  );
}

function SearchDropDown({ searchAcnhorEl, openSearchAnchorEl, handleClose }) {
  const [searchText, setSearchText] = useState("");
  const [productLoading, setProductLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const handleSearch = async (searchKey) => {
    setSearchText(searchKey);
    setProductLoading(true);
    try {
      let params = {
        limit: 30,
        page: 1,
        search: searchKey ? searchKey : "",
      };
      const { data } = await ProductServices.getProducts(params);
      setProducts(data?.result);
      if (data?.result?.length !== 0) {
        navigate("/web/marketplace/products", {
          state: { data: data?.result, searchText: searchText },
        });
        handleClose();
      } else {
        ErrorToaster("No Products Found");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProductLoading(false);
    }
  };
  return (
    <Menu
      anchorEl={searchAcnhorEl}
      open={openSearchAnchorEl}
      onClose={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          p: 1,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          ".MuiSvgIcon-root": {
            width: 20,
            height: 20,
            ml: 0.5,
            mr: 0.5,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem
        variant="subtitle2"
        sx={{
          cursor: "pointer",
          color: Colors.black,
        }}
      >
        <SearchField placeholder={t("lookingFor")} searchKey={handleSearch} />
      </MenuItem>
    </Menu>
  );
}

function Header({ onClose }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { pathname } = useLocation();
  const [navbar, setNavbar] = useState(false);
  const navigate = useNavigate();
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  const handleClose = () => setOpenDrawer(false);
  const { lang, changeLanguage, languages } = useLanguage();
  const { user } = useAuth();

  const navigations = [
    { path: "/", name: "home" },
    { path: "/blog", name: "blogs" },
    { path: "/about-us", name: "aboutUs" },
    { path: "/web/marketplace", name: "Marketplace" },
  ];

  //* For Language Dropdown
  const [langAcnhorEl, setLangAnchorEl] = useState(null);
  const openLangAnchorEl = Boolean(langAcnhorEl);

  //* For Search Dropdown
  const [searchAcnhorEl, setSearchAnchorEl] = useState(null);
  const openSearchAnchorEl = Boolean(searchAcnhorEl);

  //* For Cart Dropdown
  const [cartAnchorEl, setCartAnchorEl] = useState(null);
  const openCartDropDown = Boolean(cartAnchorEl);

  //* For User Menu Dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  const { cart } = useSelector((state) => state.cartDataReducer);

  return (
    <Box
      className={navbar ? "navbar active" : "navbar"}
      sx={{
        width: "100%",
        height: "auto",
        position: "fixed",
        zIndex: 99,
        backgroundColor: {
          xs: Colors.white,
          md: pathname === "/" ? "transparent" : "#3c86ad",
        },
      }}
    >
      <Container>
        <Grid container alignItems="center" sx={{ py: "0px" }}>
          <Grid item md={2} xs={4} sm={4}>
            <Link to="/">
              <CardMedia
                component="img"
                image={Images.logo}
                sx={{
                  height: "auto",
                  objectFit: "fill",
                  py: "15px",
                  mx: "0px",
                  width: { xs: "100%", md: "100%" },
                  color: "white",
                  display: { xs: "none", md: "block" },
                }}
              />
              <CardMedia
                component="img"
                image={Images.logo2}
                sx={{
                  height: "auto",
                  objectFit: "fill",
                  py: "15px",
                  mx: "0px",
                  width: { xs: "100%", md: "100%" },
                  color: "white",
                  display: { xs: "block", md: "none" },
                }}
              />
            </Link>
          </Grid>
          <Grid
            item
            md={6}
            xs={6}
            sm={4}
            sx={{ textAlign: { md: "center", xs: "right" } }}
          >
            <Box
              sx={{ display: { md: "none", xs: "inline-block" }, py: "8px" }}
            >
              <Link
                to="https://onelink.to/8xde5y "
                aria-label="Download"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrimaryButton
                  fullWidth
                  endIcon={
                    <SendToMobileOutlined
                      style={{ fontSize: "14px", color: Colors.black }}
                    />
                  }
                  title={t("downloadApp")}
                  btnColor={"#3c86ad3d"}
                  style={{
                    fontSize: "14px",
                    borderRadius: "6px",
                    color: Colors.black,
                  }}
                />
              </Link>
            </Box>
            <Typography
              component="nav"
              className={"navbar link"}
              sx={{
                py: "10px",
                display: { xs: "none", md: "block" },
              }}
            >
              <Typography
                component="ul"
                className={"navbar link"}
                sx={{
                  textAlign: "left",
                  color: Colors.white,
                }}
              >
                {navigations.map((item, index) => (
                  <Link
                    key={index}
                    to={item.path}
                    aria-label={item.name}
                    // onClick={() => onClose()}
                  >
                    <Typography
                      id="text"
                      component="li"
                      className={"navbar link"}
                      sx={{
                        listStyle: "none",
                        display: "inline-block",
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "10px",
                        cursor: "pointer",
                        position: "relative",
                        color: [Colors.white],
                        textTransform: "capitalize",
                        ":hover": {
                          color: [Colors.primary],
                          ".underline": {
                            display: "block",
                            opacity: "1",
                          },
                        },
                      }}
                    >
                      {t(item.name)}
                    </Typography>
                  </Link>
                ))}
              </Typography>
            </Typography>
            <Drawer
              anchor="right"
              variant="temporary"
              open={openDrawer}
              onClose={() => handleClose()}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: "80%" },
              }}
            >
              <Container>
                <Grid container sx={{ py: "14px" }} alignItems="center">
                  <Grid item md={2} xs={6} sm={6}>
                    <Link to="/">
                      <CardMedia
                        component="img"
                        image={Images.logo2}
                        sx={{
                          height: { xs: "25px", sm: "35px" },
                          objectFit: "fill",
                          py: "15px",
                          mx: "0px",
                          width: "auto",
                          color: "white",
                          display: { xs: "block", md: "none" },
                        }}
                      />
                    </Link>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={4}
                    sm={4}
                    sx={{ textAlign: { md: "center", xs: "right" } }}
                  >
                    <Box
                      sx={{
                        display: { md: "none", xs: "inline-block" },
                        py: "4px",
                      }}
                    >
                      {user?.token ? (
                        <Box
                          sx={{ mx: 2, mt: 0, cursor: "pointer" }}
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                          <ImageAvatar image={BaseUrl + user?.picture} />
                        </Box>
                      ) : (
                        <PrimaryButton
                          onClick={() => {
                            navigate("/login");
                            handleClose();
                          }}
                          title={t("login")}
                          btnColor={"#3c86ad3d"}
                          style={{
                            fontSize: "14px",
                            borderRadius: "6px",
                            color: Colors.black,
                            px: "0px",
                            minWidth: "75px",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={2}
                    sm={2}
                    sx={{ textAlign: { md: "center", xs: "right" } }}
                  >
                    <Box
                      sx={{
                        my: 1,
                        display: { md: "none", xs: "inline-block" },
                      }}
                    >
                      <IconButton onClick={() => handleClose()}>
                        <Close sx={{ color: Colors.black }} />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
              <Typography
                component="nav"
                className={"navbar link"}
                sx={{ height: 1, py: "70px", px: "20px" }}
              >
                <Typography
                  component="ul"
                  className={"navbar link"}
                  sx={{
                    color: Colors.white,
                    pl: 0,
                  }}
                >
                  {navigations.map((item, index) => (
                    <Link
                      key={index}
                      to={item.path}
                      aria-label={item.name}
                      onClick={() => onClose()}
                    >
                      <Typography
                        id="text"
                        component="li"
                        className={"navbar link"}
                        sx={{
                          listStyle: "none",
                          display: { md: "inline-block", xs: "block" },
                          alignItems: "center",
                          fontSize: "24px",
                          fontWeight: "500",
                          padding: "10px",
                          cursor: "pointer",
                          position: "relative",
                          textAlign: "left",
                          color: { md: Colors.white, xs: Colors.black },
                          textTransform: "capitalize",
                          ":hover": {
                            color: [Colors.primary],
                            ".underline": {
                              display: "block",
                              opacity: "1",
                            },
                          },
                        }}
                      >
                        {t(item.name)}
                      </Typography>
                    </Link>
                  ))}
                </Typography>
              </Typography>
              <Box
                onClick={() => handleClose()}
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: lang === "ar" ? "flex-start" : "flex-end",
                  px: "30px",
                  mb: 2.5,
                }}
              >
                <Language
                  sx={{
                    fontSize: "24px",
                    color: { md: Colors.white, xs: Colors.black },
                    mx: "6px",
                  }}
                />
                <Typography
                  variant="subtitle3"
                  onClick={() => changeLanguage("en")}
                  sx={{
                    cursor: "pointer",
                    mx: "4px",
                    color: { md: Colors.white, xs: Colors.black },
                  }}
                >
                  En
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    backgroundColor: { md: Colors.white, xs: Colors.black },
                    height: "25px",
                    mx: "8px",
                  }}
                />
                <Typography
                  variant="subtitle3"
                  onClick={() => changeLanguage("ar")}
                  sx={{
                    cursor: "pointer",
                    color: { md: Colors.white, xs: Colors.black },
                  }}
                >
                  Ar
                </Typography>
              </Box>
            </Drawer>
          </Grid>
          <Grid
            md={4}
            xs={2}
            sm={4}
            sx={{ py: { md: "8px", xs: "5px" }, textAlign: "right" }}
          >
            <IconButton
              onClick={() => setOpenDrawer(true)}
              sx={{ display: { md: "none" } }}
            >
              <MenuOutlined sx={{ color: Colors.black }} />
            </IconButton>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <IconButton onClick={(e) => setLangAnchorEl(e.currentTarget)}>
                <Language
                  sx={{
                    fontSize: "25px",
                    color: { md: Colors.white, xs: Colors.black },
                  }}
                />
              </IconButton>
              <IconButton onClick={(e) => setSearchAnchorEl(e.currentTarget)}>
                <Search
                  sx={{
                    fontSize: "25px",
                    color: { md: Colors.white, xs: Colors.black },
                  }}
                />
              </IconButton>
              <IconButton onClick={(e) => setCartAnchorEl(e.currentTarget)}>
                <Badge badgeContent={cart.length} sx={{ color: Colors.white }}>
                  <ShoppingCart />
                </Badge>
              </IconButton>
              <Box sx={{ ml: 2, py: "0px" }}>
                {" "}
                {user?.token ? (
                  <Box
                    sx={{ mx: 2, mt: { xs: 1, md: 0 }, cursor: "pointer" }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <ImageAvatar image={BaseUrl + user?.picture} />
                  </Box>
                ) : (
                  <TertiaryButton
                    onClick={() => navigate("/login")}
                    title={t("login")}
                    btnColor={"#fff"}
                    style={{
                      fontSize: "16px",
                      borderRadius: "6px",
                      color: Colors.white,
                    }}
                  />
                )}
              </Box>
            </Box>

            <DropDown
              anchorEl={anchorEl}
              openDropdown={openDropdown}
              handleClose={() => setAnchorEl(null)}
            />
            <CartDropDown
              cartAnchorEl={cartAnchorEl}
              openCartDropDown={openCartDropDown}
              handleCartClose={() => setCartAnchorEl(null)}
            />
            <LangDropDown
              langAnchorEl={langAcnhorEl}
              openLangDropdown={openLangAnchorEl}
              handleClose={() => setLangAnchorEl(null)}
              changeLanguage={changeLanguage}
            />
            <SearchDropDown
              searchAcnhorEl={searchAcnhorEl}
              openSearchAnchorEl={openSearchAnchorEl}
              handleClose={() => setSearchAnchorEl(null)}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Header;
