import { Box } from "@mui/material";
import Colors from "assets/style/Colors";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart, removeToCart } from "redux/slices/cartDataSlice";

const Counter = ({ data }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    dispatch(addToCart(data));
    setQuantity((prev) => prev + 1);
  };

  const handleDecrement = () => {
    dispatch(removeToCart(data));
    setQuantity((prev) => prev - 1);
  };

  useEffect(() => {
    setQuantity(data.qty);
  }, [data]);

  return (
    <Box
      sx={{
        border: `1px solid ${Colors.primary}`,
        borderRadius: "6px",
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        component={"span"}
        sx={{ cursor: "pointer", px: "10px" }}
        onClick={() => handleDecrement()}
      >
        -
      </Box>
      <Box component={"span"}>{quantity}</Box>
      <Box
        component={"span"}
        sx={{ cursor: "pointer", px: "10px" }}
        onClick={() => handleIncrement()}
      >
        +
      </Box>
    </Box>
  );
};

export default Counter;
