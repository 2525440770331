import { get, post } from 'apis';
import OrderRoutes from './Order.Routes';

const OrderServices = {
  uploadPrescription: async (obj) => {
    const data = await post(OrderRoutes.uploadPrescription, obj);
    return data;
  },
  createOrder: async (obj) => {
    const data = await post(OrderRoutes.createOrder, obj);
    return data;
  },
  getOrders: async (obj) => {
    const data = await get(OrderRoutes.getOrders, obj);
    return data;
  },
  getOrderDetailById: async (id) => {
    const data = await get(OrderRoutes.getOrderDetailById + `?id=${id}`);
    return data;
  },
  updateOrderStatus: async (id, obj) => {
    const data = await post(OrderRoutes.updateOrderStatus + `?id=${id}`, obj);
    return data;
  },
  applyVoucher: async (id, obj) => {
    const data = await post(OrderRoutes.applyVoucher + `?id=${id}`, obj);
    return data;
  },
  removeVoucher: async (id, obj) => {
    const data = await post(OrderRoutes.removeVoucher + `?id=${id}`, obj);
    return data;
  },
  confirmOrderByCod: async (id, obj) => {
    const data = await post(OrderRoutes.confirmOrderByCod + `?id=${id}`, obj);
    return data;
  },
  confirmOrderByCard: async (id, obj) => {
    const data = await post(OrderRoutes.confirmOrderByCard + `?id=${id}`, obj);
    return data;
  },
  confirmPayment: async (obj) => {
    const data = await post(OrderRoutes.confirmPayment, obj);
    return data;
  },
  placeOrderCod: async (obj) => {
    const data = await post(OrderRoutes.placeOrderCod, obj);
    return data;
  },
  placeOrderCard: async (obj) => {
    const data = await post(OrderRoutes.placeOrderCard, obj);
    return data;
  },
}

export default OrderServices