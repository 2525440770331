import React, { Fragment, useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navigations from "Navigations";
import Colors from "assets/style/Colors";
import Images from "assets/images/Images";
import { useLanguage } from "context/UseContext";

const drawerWidth = 270;

function Sidebar({ openDrawer, closeDrawer }) {
  const { t } = useTranslation();
  const { lang } = useLanguage();
  const { pathname } = useLocation();

  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  // *For Sub Child Active
  const handleSubChildActive = (item) => {
    return (
      item.childPath?.indexOf(pathname) !== -1 &&
      item.childPath?.indexOf(pathname)
    );
  };

  const drawer = (
    <Fragment>
      <Toolbar sx={{ pt: 2, justifyContent: { xs: "start", md: "center" } }}>
        <Link to="/" aria-label="Scriptio">
          <Box
            component="img"
            loading="lazy"
            src={Images.logo2}
            alt={"Scriptio"}
            sx={{ width: "180px" }}
          />
        </Link>
        <Box sx={{ display: { xs: "block", md: "none" }, flexGrow: 1 }} />
        <Close
          sx={{
            display: { xs: "block", md: "none" },
            bgcolor: Colors.primary,
            color: Colors.white,
            borderRadius: 1,
            cursor: "pointer",
          }}
          onClick={() => closeDrawer()}
        />
      </Toolbar>
      <Divider Divider sx={{ width: "80%", mx: "auto" }} />
      <List
        sx={{
          px: 2,
          direction: lang === "ar" ? "ltr" : lang === "en" ? "ltr" : "rtl",
        }}
      >
        {Navigations.map((item, index) => (
          <Fragment key={index}>
            <ListItemButton
              key={index}
              component={item.path ? Link : "div"}
              to={item.path ?? ""}
              aria-label={item?.label}
              onClick={() => {
                item.childPath && handleCollapse(item.label);
                closeDrawer();
              }}
              sx={{
                borderRadius: 2,
                mt: 1.5,
                backgroundImage:
                  item.path === pathname ||
                    handleSubChildActive(item) ||
                    handleSubChildActive(item) === 0
                    ? Colors.primaryGradient
                    : "",
                ".MuiListItemIcon-root": {
                  color: Colors.tertiary,
                },
                "&:hover": {
                  backgroundImage: Colors.primaryGradient,
                  ".MuiTypography-root": {
                    color: Colors.white,
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  bgcolor: Colors.white,
                  minWidth: "auto",
                  mr: 1,
                  borderRadius: "6px",
                  p: "4px",
                  svg: {
                    height: "20px",
                    width: "auto",
                    "path": { fill: Colors.primary }
                  },
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      color:
                        item.path === pathname ||
                          handleSubChildActive(item) ||
                          handleSubChildActive(item) === 0
                          ? Colors.white
                          : Colors.secondary,
                    }}
                  >
                    {t(item.label)}
                  </Typography>
                }
              />
              {item?.children &&
                (expand.indexOf(item.label) !== -1 ? (
                  <ExpandLess sx={{ color: Colors.textSecondary }} />
                ) : (
                  <ExpandMore sx={{ color: Colors.textSecondary }} />
                ))}
            </ListItemButton>
            {item?.children && (
              <Collapse
                in={
                  expand.indexOf(item.label) !== -1 ||
                    item.childPath?.indexOf(pathname) !== -1
                    ? true
                    : false
                }
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.children.map((child, i) => (
                    <ListItemButton
                      key={i}
                      component={child.path ? Link : "div"}
                      to={child.path ?? ""}
                      aria-label={child?.label}
                      onClick={() => {
                        child.path && handleCollapse(child.label);
                        closeDrawer();
                      }}
                      sx={{
                        borderRadius: 2,
                        mt: 1,
                        "&:hover": {
                          backgroundImage: Colors.primaryGradient,
                          ".MuiTypography-root": {
                            color: Colors.white,
                          },
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            child.path === pathname
                              ? Colors.primary
                              : Colors.textSecondary,
                          minWidth: 40,
                          ".MuiSvgIcon-root": { fontSize: "14px" },
                        }}
                      >
                        {child.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            sx={{
                              fontSize: 13,
                              fontWeight:
                                child.path === pathname ? "bold" : "normal",
                              color:
                                child.path === pathname
                                  ? Colors.primary
                                  : Colors.textSecondary,
                            }}
                          >
                            {child.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>
    </Fragment>
  );

  return (
    <Fragment>
      {/* ========== Mobile Responsive Drawer ========== */}
      <Drawer
        anchor="left"
        variant="temporary"
        open={openDrawer}
        onClose={closeDrawer}
        sx={{
          display: { xs: "block", md: "none" },
          width: drawerWidth + 20,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            height: "calc(100vh - 20px)",
            boxSizing: "border-box",
            bgcolor: Colors.white,
            m: "10px",
            border: "none",
            borderRadius: "10px",
            overflowY: "scroll",
            boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
            "&::-webkit-scrollbar": {
              width: 6,
              bgcolor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: 1,
              bgcolor: Colors.primary,
            },
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* ========== SImple Drawer ========== */}
      <Drawer
        anchor="left"
        variant="permanent"
        open
        sx={{
          display: { xs: "none", md: "block" },
          width: drawerWidth + 20,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            height: "calc(100vh - 20px)",
            boxSizing: "border-box",
            bgcolor: Colors.white,
            m: "10px",
            border: "none",
            borderRadius: "10px",
            overflowY: "scroll",
            boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
            "&::-webkit-scrollbar": {
              width: 6,
              bgcolor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: 1,
              bgcolor: Colors.primary,
            },
          },
        }}
      >
        {drawer}
      </Drawer>
    </Fragment>
  );
}

export default Sidebar;
