import React, { Suspense, useState } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { CircleLoading } from "components/loaders/Loaders";
import { useLanguage } from "context/UseContext";
import { onMessageListener } from "../../firebase/firebase";

function DashboardLayout() {
  const { lang } = useLanguage();

  const [openDrawer, setOpenDrawer] = useState(false);

  onMessageListener().then((payload) => {
    console.log('Received message: layout', payload);
    // setNotification(payload);
  });

  return (
    <Box
      dir={lang === "ar" ? "rtl" : "ltr"}
      sx={{
        bgcolor: "rgb(240, 242, 245)",
        display: "flex",
        p: "10px",
        minHeight: "calc(100vh - 20px)",
      }}
    >
      {/* ========== Sidebar ========== */}
      <Sidebar
        openDrawer={openDrawer}
        closeDrawer={() => setOpenDrawer(false)}
      />

      <Box component="main" sx={{ width: 1 }}>
        {/* ========== Header ========== */}
        <Header openDrawer={() => setOpenDrawer(true)} />

        <Box sx={{ position: "relative", overflowY: "auto", py: 2.5, px: 1 }}>
          <Suspense fallback={<CircleLoading />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
