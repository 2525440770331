export const ErrorHandler = (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            const lang = localStorage.getItem('lang')
            localStorage.clear()
            localStorage.setItem('lang', lang)
            window.location.reload()
        }
        return error.response.data;
    } else if (error.request) {
        return error.message;
    } else {
        return 'Something went wrong';
    }
};
