import { FormControl, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function InputField(props) {
  const { t } = useTranslation();

  const {
    variant,
    dir,
    label,
    placeholder,
    defaultValue,
    value,
    register,
    error,
    rows,
    multiline,
    size,
    type,
    InputProps,
    disabled,
    inputProps,
    onInput,
    onBlur,
    helperText,
    style,
    onChange,
    onClick,
  } = props;

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ my: 1.3, ...style, ".MuiFormHelperText-root": { ml: 0 } }}
    >
      <TextField
        variant={variant ?? "outlined"}
        dir={dir}
        value={value}
        size={size}
        type={type}
        disabled={disabled}
        label={t(label)}
        placeholder={t(placeholder)}
        defaultValue={defaultValue}
        error={error && true}
        multiline={multiline}
        rows={rows}
        onBlur={onBlur}
        InputProps={InputProps} // *For Component Value
        inputProps={inputProps} // *For Input Attributes
        onInput={onInput}
        helperText={helperText}
        onChange={(e) => onChange(e)}
        {...register}
        onClick={onClick}
      />
      {error && (
        <Typography color="error" sx={{ fontSize: 12, textAlign: "left" }}>
          {t(error)}
        </Typography>
      )}
    </FormControl>
  );
}

export default InputField;
