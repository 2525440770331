const AddressRoutes = {
  getAddressLists: '/address',
  createAddress: '/address/create',
  updateAddress: '/address/update',
  deleteAddress: '/address/delete',
  getSettings: '/settings',
  getTermsConditions: '/pages/terms',
  getAbout: '/pages/aboutus',
};

export default AddressRoutes