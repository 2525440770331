import { Avatar } from "@mui/material";

import Colors from "assets/style/Colors";

export const ImageAvatar = ({ image }) => {
  return (
    <Avatar
      src={image}
      alt="avatar"
      sx={{
        width: 40,
        height: 40,
        border: `1px solid ${Colors.textSecondary}`,
        '.MuiAvatar-img': { objectFit: 'contain' }
      }}
    />
  );
}