import React, { lazy, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import "i18n/i18n";
import Colors from "./assets/style/Colors";
import { ToasterContainer } from "./components/toaster/Toaster";
import PublicRoutes from "./routes/PublicRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import DashboardRoutes from "./routes/DashboardRoutes";
import WebLayout from "./layout/web";
import DashboardLayout from "./layout/dashboard";
import ScrollToTop from "./hooks/ScrollToTop";
import Storage from "utils/Storage";
import useProvideLanguage from "hooks/useProvideLanguage";
import { useAuth } from "context/UseContext";
import { LanguageContext } from "context/CreateContext";
import { onMessageListener } from "./firebase/firebase";
import ReactGA from "react-ga";

const PageNotFound = lazy(() => import("views/404"));

ReactGA.initialize("UA-199933404-1");

function App() {
  const language = useProvideLanguage();
  const { user } = useAuth();
  const { getStorageItem } = Storage();

  const lang = getStorageItem("lang");

  const theme = createTheme({
    direction: lang === "ar" ? "rtl" : "ltr",
    palette: {
      primary: {
        main: Colors.primary,
        contrastText: Colors.white,
      },
      secondary: {
        main: Colors.secondary,
        contrastText: Colors.white,
      },
      danger: {
        main: Colors.danger,
        contrastText: Colors.white,
      },
    },
    typography: {
      fontFamily: ["AktivGrotesk"],
      h1: {
        fontSize: 100,
        fontWeight: 900,
        color: Colors.white,
      },
      h2: {
        fontSize: 60,
        fontWeight: 700,
        color: Colors.textSecondary,
      },
      h3: {
        fontSize: 56,
        fontWeight: 700,
        color: Colors.textSecondary,
      },
      h4: {
        fontSize: 46,
        fontWeight: 700,
      },
      h5: {
        fontSize: 38,
        fontWeight: 700,
      },
      h6: {
        fontSize: 30,
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 34,
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: 26,
        fontWeight: 400,
      },
      subtitle3: {
        fontSize: 24,
        fontWeight: 400,
      },
      subtitle4: {
        fontSize: 40,
        fontWeight: 400,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },
      body3: {
        fontSize: 18,
        fontWeight: 400,
      },
      body4: {
        fontSize: 20,
        fontWeight: 600,
      },
      body5: {
        fontSize: 11,
        fontWeight: 400,
      },
    },
  });

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const cacheLtr = createCache({
    key: "muiltr",
  });

  return (
    <LanguageContext.Provider value={language}>
      <CacheProvider value={lang === "ar" ? cacheRtl : cacheLtr}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            {/* ========== Toaster ========== */}
            <ToasterContainer />

            <Routes>
              <Route element={<WebLayout />}>
                <Route path={"*"} element={<Navigate to="/404" />} />
                <Route path={"/404"} element={<PageNotFound />} />
                {PublicRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              </Route>

              <Route
                element={
                  user?.token ? <Navigate to="/dashboard" /> : <WebLayout />
                }
              >
                {AuthRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              </Route>

              <Route
                element={
                  user?.token ? <DashboardLayout /> : <Navigate to="/login" />
                }
              >
                {DashboardRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              </Route>
            </Routes>

            {/* ========== Scroll To Top ========== */}
            <ScrollToTop />
          </ThemeProvider>
        </BrowserRouter>
      </CacheProvider>
    </LanguageContext.Provider>
  );
}

export default App;
