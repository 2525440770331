import { Fragment, useState } from "react";
import { Close, CreditCard } from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import OrderServices from "apis/order/OrderServices";
import { DeleteIcon } from "assets/images/Images";
import Colors from "assets/style/Colors";
import { PrimaryButton } from "components/buttons/Buttons";
import InputField from "components/fields/InputField";
import { ErrorToaster } from "components/toaster/Toaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth, useLanguage } from "context/UseContext";
import { CalenderIcon } from "assets/images/Images";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import moment from "moment";

function PaymentDialog({
  open,
  onClose,
  orderDetail,
  getOrderDetailById,
  confirmOrder,
}) {
  const CryptoJS = require("crypto-js");
  const { user } = useAuth();
  const { lang } = useLanguage();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [voucherLoading, setVoucherLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("cod");
  const [riderTip, setRiderTip] = useState(0);

  const [selectedValue, setSelectedValue] = useState("instant");
  const [nextDaySelection, setNextDaySelection] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const handleNextDaySelectionChange = (event) => {
    setNextDaySelection(event.target.value);
  };

  // *For Apply Voucher
  const applyVoucher = async (formData) => {
    setVoucherLoading(true);
    try {
      let obj = {
        code: formData.voucher,
      };
      const { responseCode } = await OrderServices.applyVoucher(
        orderDetail?.result?._id,
        obj
      );
      if (responseCode === 200) {
        getOrderDetailById();
      }
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    } finally {
      setVoucherLoading(false);
    }
  };

  // *For Remove Voucher
  const removeVoucher = async (formData) => {
    try {
      const { responseCode } = await OrderServices.removeVoucher(
        orderDetail?.result?._id
      );
      if (responseCode === 200) {
        getOrderDetailById();
      }
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    }
  };

  const tapPayment = () => {
    try {
      let obj = {
        amount: orderDetail?.billing?.total_amount + Number(riderTip),
        firstName: user?.name,
        lastName: user?.name,
        email: user?.email,
        phone: user?.phone.split("971")[1],
        orderNo: orderDetail?.result?.order_id,
        riderTip: riderTip,
      };
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(obj),
        "Qb7C^sjNVfgd85^Wctv"
      ).toString();
      window.location.href = `https://securepay.e-rx.info/?pay=${encodeURIComponent(
        encryptedData
      )}`;
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    }
  };

  const handleClick = () => {
    if (paymentMethod === "card") {
      tapPayment();
    } else {
      let obj = {
        method: paymentMethod,
        tip: riderTip,
        delivery: {},
      };
      let timeRange =
        nextDaySelection === "morning"
          ? "6am - 10am"
          : nextDaySelection === "afternoon"
          ? "12pm - 4pm"
          : "5pm - 8pm";
      if (selectedValue === "instant") {
        obj.delivery = {
          nature: "instant",
          slot: "",
          time_range: "",
          date_time: moment(new Date())
            .add(4, "hours")
            .locale("en")
            .format("YYYY-MM-DD HH:mm"),
        };
      } else if (selectedValue === "nextDay") {
        obj.delivery = {
          nature: "next_day",
          slot: nextDaySelection,
          time_range: timeRange,
          date_time: moment(new Date())
            .add(1, "day")
            .locale("en")
            .format("YYYY-MM-DD 00:00"),
        };
      } else if (selectedValue === "customDay") {
        obj.delivery = {
          nature: "custom",
          slot: nextDaySelection,
          time_range: timeRange,
          date_time: moment(selectedDate)
            .locale("en")
            .format("YYYY-MM-DD HH:mm"),
        };
      }
      confirmOrder(obj);
    }
    onClose();
    reset();
  };

  return (
    <Dialog
      dir={lang === "ar" ? "rtl" : "ltr"}
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          my: { xs: 2, md: 4 },
          mx: { xs: 1, md: 4 },
          width: { xs: "100%", md: "60%" },
          height: "auto",
          borderRadius: 2,
          py: { xs: 1, md: 1.5 },
          px: { xs: 0.8, md: 1.5 },
        },
      }}
    >
      <IconButton
        color="primary"
        onClick={() => {
          onClose();
          reset();
        }}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close />
      </IconButton>
      <DialogTitle
        sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
      >
        {t("paymentMethod")}
      </DialogTitle>
      <DialogContent>
        <Card
          sx={{
            p: 1.5,
            borderRadius: "12px",
            boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
            mb: 1,
          }}
        >
          <Box display="flex" alignItems="center">
            <CreditCard sx={{ m: 1, color: Colors.primary }} />
            <Typography sx={{ fontWeight: "bold" }}>
              {t("totalBill")}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ p: 1 }}>{t("amount")}</Typography>
            <Typography sx={{ fontWeight: "bold", p: 1 }}>
              {t("aed")} {orderDetail?.billing?.amount?.toFixed(2)}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ p: 1 }}>{t("deliveryCharges")}</Typography>
            <Typography sx={{ fontWeight: "bold", p: 1 }}>
              {t("aed")} {orderDetail?.billing?.delivery_charges?.toFixed(2)}
            </Typography>
          </Box>
          {riderTip !== 0 && (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ p: 1 }}>{t("Tip")}</Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {t("aed")} {Number(riderTip).toFixed(2)}
              </Typography>
            </Box>
          )}
          {orderDetail?.billing?.discount && (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ p: 1 }}>{t("discountAmount")}</Typography>
              <Typography sx={{ fontWeight: "bold", p: 1 }}>
                {t("aed")} {orderDetail?.billing?.discount?.toFixed(2)}
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontWeight: "bold", p: 1 }}>
              {t("totalAmount")}
            </Typography>
            <Typography sx={{ fontWeight: "bold", p: 1 }}>
              {t("aed")}{" "}
              {(orderDetail?.billing?.total_amount + Number(riderTip)).toFixed(
                2
              )}
            </Typography>
          </Box>
        </Card>
        {orderDetail?.billing?.discount ? (
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Typography variant={"body1"} sx={{ textDecoration: "underline" }}>
              Voucher applied {orderDetail?.billing?.voucher_code}
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ color: Colors.danger, cursor: "pointer" }}
              onClick={() => removeVoucher()}
            >
              <DeleteIcon /> remove
            </Typography>
          </Box>
        ) : (
          <Fragment>
            <Typography variant={"body1"} sx={{ mt: 2 }}>
              {t("applyVoucher")}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(applyVoucher)}
              sx={{ display: "flex", gap: "10px", alignItems: "baseline" }}
            >
              <InputField
                size={"small"}
                placeholder={"enterVoucherCode"}
                error={errors?.voucher?.message}
                register={register("voucher", {
                  required: "enterVoucherCode",
                })}
              />
              <PrimaryButton
                title="apply"
                type="submit"
                loading={voucherLoading}
                btnColor={Colors.secondaryGradient}
              />
            </Box>
          </Fragment>
        )}

        <Box sx={{ mt: 1.5 }}>
          <Typography variant={"subtitle2"} sx={{ fontWeight: "bold" }}>
            {t("leaveTip")}
          </Typography>
          <Typography variant={"body2"}>{t("goesRider")}</Typography>
          <Box
            sx={{
              my: 1.5,
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                bgcolor: riderTip === 0 ? Colors.primary : Colors.white,
                py: 1,
                px: 2,
                borderRadius: "8px",
                boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
                cursor: "pointer",
              }}
              onClick={() => setRiderTip(0)}
            >
              <Typography
                variant={"body1"}
                sx={{
                  color: riderTip === 0 ? Colors.white : Colors.black,
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
              >
                {t("noTip")}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: riderTip === 2 ? Colors.primary : Colors.white,
                py: 1,
                px: 2,
                borderRadius: "8px",
                boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
                cursor: "pointer",
              }}
              onClick={() => setRiderTip(2)}
            >
              <Typography
                variant={"body1"}
                sx={{
                  color: riderTip === 2 ? Colors.white : Colors.black,
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
              >
                {t("aed2")}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: riderTip === 5 ? Colors.primary : Colors.white,
                py: 1,
                px: 2,
                borderRadius: "8px",
                boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
                cursor: "pointer",
              }}
              onClick={() => setRiderTip(5)}
            >
              <Typography
                variant={"body1"}
                sx={{
                  color: riderTip === 5 ? Colors.white : Colors.black,
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
              >
                {t("aed5")}
              </Typography>
            </Box>
            <Box>
              <InputField
                size={"small"}
                type={"number"}
                placeholder={"amount"}
                error={errors?.tip?.message}
                onChange={(e) =>
                  setRiderTip(e.target.value ? e.target.value : 0)
                }
                style={{ width: "105px" }}
              />
            </Box>
          </Box>
        </Box>

        <Card
          sx={{
            p: 1.5,
            borderRadius: "12px",
            boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
            mb: 1,
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={selectedValue}
              onChange={(e) => {
                setSelectedValue(e.target.value);
              }}
            >
              <FormControlLabel
                value="instant"
                control={<Radio />}
                label={
                  <div>
                    <span>{t("Instant")}</span>
                    <Typography variant="body2" style={{ color: Colors.grey }}>
                      {t("WithIn4Hours")}
                    </Typography>
                  </div>
                }
              />
              <FormControlLabel
                value="nextDay"
                control={<Radio />}
                label={t("NextDay")}
              />
              {selectedValue === "nextDay" && (
                <RadioGroup
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    "& .MuiFormControlLabel-root": {
                      m: 0,
                      width: "80px",
                      justifyContent: "center",
                    },
                  }}
                  value={nextDaySelection}
                  onChange={handleNextDaySelectionChange}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          bgcolor:
                            nextDaySelection === "morning"
                              ? "#effcff"
                              : "#ffffff",
                          border:
                            nextDaySelection === "morning"
                              ? `1px solid ${Colors.primary}`
                              : `1px solid ${Colors.iron}`,
                          borderRadius: "4px",
                          p: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormControlLabel
                          value="morning"
                          control={<Radio sx={{ display: "none" }} />}
                          label={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <Typography variant="body1" component="div">
                                {t("Morning")}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ color: "gray" }}
                              >
                                6-10 {t("am")}
                              </Typography>
                            </div>
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          bgcolor:
                            nextDaySelection === "afternoon"
                              ? "#effcff"
                              : "#ffffff",
                          border:
                            nextDaySelection === "afternoon"
                              ? `1px solid ${Colors.primary}`
                              : `1px solid ${Colors.iron}`,
                          borderRadius: "4px",
                          p: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormControlLabel
                          value="afternoon"
                          control={<Radio sx={{ display: "none" }} />}
                          label={
                            <div>
                              <Typography variant="body1" component="div">
                                {t("Afternoon")}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ color: "gray" }}
                              >
                                12-4 {t("pm")}
                              </Typography>
                            </div>
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          bgcolor:
                            nextDaySelection === "evening"
                              ? "#effcff"
                              : "#ffffff",
                          border:
                            nextDaySelection === "evening"
                              ? `1px solid ${Colors.primary}`
                              : `1px solid ${Colors.iron}`,
                          borderRadius: "4px",
                          p: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormControlLabel
                          value="evening"
                          control={<Radio sx={{ display: "none" }} />}
                          label={
                            <div>
                              <Typography variant="body1" component="div">
                                {t("Evening")}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ color: "gray" }}
                              >
                                5-8 {t("pm")}
                              </Typography>
                            </div>
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
              <FormControlLabel
                value="customDay"
                control={<Radio />}
                label={t("CustomDate")}
              />
              {selectedValue === "customDay" && (
                <Box sx={{ mb: "12px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      slots={{ openPickerIcon: CalenderIcon }}
                      defaultValue={dayjs()}
                      label={t("selectDate")}
                      onChange={(date) => {
                        const formattedDate = new Date(date);
                        setSelectedDate(formattedDate);
                      }}
                      disablePast
                    />
                  </LocalizationProvider>
                </Box>
              )}
              {selectedValue === "customDay" && selectedDate !== "" && (
                <RadioGroup
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    "& .MuiFormControlLabel-root": {
                      m: 0,
                      width: "80px",
                      justifyContent: "center",
                    },
                  }}
                  value={nextDaySelection}
                  onChange={handleNextDaySelectionChange}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          bgcolor:
                            nextDaySelection === "morning"
                              ? "#effcff"
                              : "#ffffff",
                          border:
                            nextDaySelection === "morning"
                              ? `1px solid ${Colors.primary}`
                              : `1px solid ${Colors.iron}`,
                          borderRadius: "4px",
                          p: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormControlLabel
                          value="morning"
                          control={<Radio sx={{ display: "none" }} />}
                          label={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <Typography variant="body1" component="div">
                                {t("Morning")}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  color: "gray",
                                  textAlign: "center",
                                }}
                              >
                                6-10 {t("AM")}
                              </Typography>
                            </div>
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          bgcolor:
                            nextDaySelection === "afternoon"
                              ? "#effcff"
                              : "#ffffff",
                          border:
                            nextDaySelection === "afternoon"
                              ? `1px solid ${Colors.primary}`
                              : `1px solid ${Colors.iron}`,
                          borderRadius: "4px",
                          p: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormControlLabel
                          value="afternoon"
                          control={<Radio sx={{ display: "none" }} />}
                          label={
                            <div>
                              <Typography variant="body1" component="div">
                                {t("Afternoon")}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  color: "gray",
                                  textAlign: "center",
                                }}
                              >
                                12-4 {t("PM")}
                              </Typography>
                            </div>
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <Box
                        sx={{
                          bgcolor:
                            nextDaySelection === "evening"
                              ? "#effcff"
                              : "#ffffff",
                          border:
                            nextDaySelection === "evening"
                              ? `1px solid ${Colors.primary}`
                              : `1px solid ${Colors.iron}`,
                          borderRadius: "4px",
                          p: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormControlLabel
                          value="evening"
                          control={<Radio sx={{ display: "none" }} />}
                          label={
                            <div>
                              <Typography variant="body1" component="div">
                                {t("Evening")}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  color: "gray",
                                  textAlign: "center",
                                }}
                              >
                                5-8 {t("PM")}
                              </Typography>
                            </div>
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            </RadioGroup>
          </FormControl>
        </Card>

        <Card
          sx={{
            p: 1.5,
            borderRadius: "12px",
            boxShadow: `rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px`,
            mb: 1,
          }}
        >
          <Typography variant={"subtitle2"} sx={{ fontWeight: "bold", mt: 2 }}>
            {t("paymentMethod")}
          </Typography>
          <Box>
            <Radio
              name="payment-method"
              value="card"
              checked={paymentMethod === "card"}
              onChange={(event) => setPaymentMethod(event.target.value)}
            />
            <Typography component="span" variant={"body1"}>
              {t("debitCredit")}
            </Typography>
          </Box>
          <Box>
            <Radio
              name="payment-method"
              value="cod"
              checked={paymentMethod === "cod"}
              onChange={(event) => setPaymentMethod(event.target.value)}
            />
            <Typography component="span" variant={"body1"}>
              {t("PayOnDelivery")}
            </Typography>
          </Box>
        </Card>
        <PrimaryButton
          fullWidth
          title="confirmOrder"
          onClick={() => handleClick()}
          btnColor={Colors.primaryGradient}
        />
      </DialogContent>
    </Dialog>
  );
}

export default PaymentDialog;
