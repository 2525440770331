import { useState } from "react";
import { useTranslation } from 'react-i18next';
import Storage from "utils/Storage";

function useProvideLanguage() {

  const { i18n } = useTranslation();
  const { setStorageItem, getStorageItem } = Storage();

  // *Languages
  const languages = [
    { id: 'en', code: 'US', label: 'english' },
    { id: 'ar', code: 'AE', label: 'arabic' },
  ];

  const defaultLang = getStorageItem('lang')

  const [lang, setLang] = useState(defaultLang ?? 'en');

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    setLang(lang)
    setStorageItem('lang', lang)
  };

  return {
    lang,
    changeLanguage,
    languages
  };
}

export default useProvideLanguage;