import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cart: [],
  totalAmount: 0
}

export const cartDataSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { _id, price, discounted_price } = action.payload;
      const actualPrice = discounted_price > 0 ? discounted_price : price
      const existingItem = state.cart.find(item => item._id === _id);
      state.totalAmount += actualPrice
      if (existingItem) {
        existingItem.qty += 1;
        existingItem.totalPrice += actualPrice;
      } else {
        state.cart.push({ ...action.payload, qty: 1, totalPrice: actualPrice });
      }
    },
    removeToCart: (state, action) => {
      const { _id, price, discounted_price } = action.payload;
      const actualPrice = discounted_price > 0 ? discounted_price : price
      const existingItem = state.cart.find(item => item._id === _id);
      state.totalAmount -= actualPrice
      if (existingItem.qty > 1) {
        existingItem.qty -= 1;
        existingItem.totalPrice -= actualPrice;
      } else {
        const index = state.cart.findIndex(item => item._id === _id);
        if (index !== -1) {
          state.cart.splice(index, 1);
        }
      }
    },
    clearCart: (state, action) => {
      state.totalAmount = 0
      state.cart = []
    },
  },
})


// Action creators are generated for each case reducer function
export const { addToCart, removeToCart, clearCart } = cartDataSlice.actions

export default cartDataSlice.reducer

