import axios from "axios";

export const BaseUrl = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: BaseUrl + "/api/v1",
});

instance.interceptors.request.use((request) => {
  let user = JSON.parse(localStorage.getItem("user"));

  request.headers = {
    Accept: "application/json, text/plain, */*",
    Authorization: `Bearer ${user?.token}`,
  };
  return request;
});

instance.interceptors.response.use(
  (response) => {
    if (response) {
      return response;
    }
  },
  (error) => Promise.reject(error)
);

export default instance;
